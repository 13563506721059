import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectDateTime } from '../../actions/PageActions';
import PageView from '../PageView';
import CheckBox from '../components/CheckBox';
import { BtnBack } from '../components/BackBtn';


class SMScode extends Component {
  constructor(props) {
    super(props)
    this.state={
    }
    this.input={}

    this.sendCode()
  }

  sendCode(){
    const {user_input,user_data} = this.props

    global.API_task({
      operation:"getSMScode",
      company_id:user_input.selected_company.id,
      phone:user_data.phone.replace("+",""),
      fullname:user_data.fullname
    }).then(res=>{
      
    })
  }

  makeBook(){
    const {user_input,user_data} = this.props
    
    global.API_task({
      operation:"makeBook",
      company_id:user_input.selected_company.id,
      phone:user_data.phone.replace("+",""),
      fullname:user_data.fullname,
      email:user_data.email,
      code:this.input.SMScode.value,
      notify_by_sms:user_data.notify_by_sms==null ? 0 : user_data.notify_by_sms,

      services_id:user_input.service.id,
      master_id:user_input.master!=null ? user_input.master.id : 0,
      datetime:user_input.selected_dateTime
    }).then(res=>{
      if(res.data.record_id!=null){
        this.props.openPage("SuccessPage")
      }
    })

  }

  render() {
    const {user_input} = this.props
    const {} = this.state

    return(
      <PageView>
        <div className="row">
          <div className="col-lg-4 col-md-3"/>
          <div className="col-lg-4 col-md-6">
            <p className="text-center">На указанный Вами номер придет сообщение, содержащее код, который нужно написать в поле ниже для подтверждения записи</p>

            <Input
              ref={ref=>this.input.SMScode=ref}
              placeholder="*****"
            />


            <Btn className="btn btn-light btn-lg d-block mx-auto"
              onClick={()=>{
                if(!global.checkReqvireds(this.input,["AlertSMS","Email"])) return
                this.makeBook()
              }}>
              Подтвердить
            </Btn>
          </div>
          <div className="col-lg-4 col-md-3"/>

          <BtnBack className="btn btn-light btn-sm"
            onClick={()=>{
              this.props.openPage("UserInfo")
            }}>Назад</BtnBack>
        </div>
      </PageView>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectDateTime: (arg) => dispatch(selectDateTime(arg)), 
    
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
    user_data: store.user.user_data,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SMScode)

const Btn = styled.div`
width:200px;
position:relative;
z-index:2;
font-size:16px;
transition:.7s ease-in-out;
box-shadow:10px 10px 30px rgba(219, 154, 99, 0.0);
margin-top:20px;
&:hover{
  transform:scale(1.1);
  background:#db9a63;
  border-color:#db9a63;
  color:#6e431f;
  box-shadow:10px 10px 30px rgba(219, 154, 99, 0.26);
}
`

const Input = styled.input`
background: rgb(226,226,227);
border-radius: 10px;
border: 1px solid rgb(226,226,227);
line-height: 0px;
height: 50px;
padding-top: 0px;
text-align: center;
letter-spacing: 20px;
width: 200px;
color: black;
font-size: 20px;
-webkit-transition: .3s;
transition: .3s;
margin-left: auto;
margin-right: auto;
display: block;
padding-left:20px;
`