import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster,selectDateTime} from '../../actions/PageActions';
import PageView from '../PageView';
import CollapseListElement from '../components/CollapseListElement';
import Loading from '../components/Loading';
import { BtnBack } from '../components/BackBtn';



class SelectDate extends Component {
  constructor(props) {
    super(props)
    this.state={
      nowLoading:false,
      DateTimePerCompanies:[],
      selectedDate:global.makeNormalDate(new Date(),"yyyy-mm-dd")
    }
  
    this.getDateTimePerCompanies()
  }

  getDateTimePerCompanies(){
    const {user_input} = this.props
    const {selectedDate} = this.state

    global.API_task({
      operation:"getDateTimePerCompanies",
      master_id:user_input.master!=null ? user_input.master.id : null,
      service_id:user_input.service.id,
      date:selectedDate
    }).then(res=>{
        this.setState({DateTimePerCompanies:res,nowLoading:false})
    }).catch(er=>{
        
    })
  }


  render() {
    const {DateTimePerCompanies,selectedDate,nowLoading} = this.state
    const {user_input} = this.props

    if(DateTimePerCompanies.length==0){
      return <Loading/>
    }

    return(
      <PageView>
        {nowLoading?
          <Loader>
            <Loading/>
          </Loader>
        :null}
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <Label for="date" >Выбор даты посещения</Label>
            <Input id="date" min={global.makeNormalDate(new Date(),"yyyy-mm-dd")} type="date" className="form-control" value={selectedDate} onChange={(e)=>{
              if(!nowLoading){
                e.target.blur()
                this.setState({selectedDate:e.target.value,nowLoading:true})
                setTimeout(()=>this.getDateTimePerCompanies(),10)
              }
            }}/>
          </div>
          <div className="col-md-4"></div>
        </div>
        
        <div className="row">
          {DateTimePerCompanies.map((comp,i)=>(
            <div className="col-4">
              <Title>{comp.title}</Title>
              <Scroll>
                {comp.data.map((dates,y)=>(
                  <DatesView length={dates.sum_length/60} onClick={()=>{
                    this.props.selectDateTime({
                      dateTime:dates.datetime,
                      company:comp
                    })
                    this.props.openPage("UserInfo")
                  }}>
                    <DatesTitle>{dates.time}</DatesTitle>
                  </DatesView>
                ))}
              </Scroll>
            </div>
          ))}
        </div>
        <div className="row">
          <BtnBack className="btn btn-light btn-sm mt-4 d-block mx-auto"
          onClick={()=>{
            this.props.openPage("SelectService")
            this.props.selectService(null)
          }}>Назад</BtnBack>
        </div>
      </PageView>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectOrderType: (arg) => dispatch(selectOrderType(arg)), 
    selectMaster: (arg) => dispatch(selectMaster(arg)), 
    selectService: (arg) => dispatch(selectService(arg)), 
    selectDateTime: (arg) => dispatch(selectDateTime(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectDate)

const Loader = styled.div`
height:100vh;
width:100vw;
z-index:10;
background:rgba(0,0,0,0.1);
top:0px;
left:0px;
position:fixed;
`

const Label = styled.label`
height: 17px;
font-size: 12px;
margin-bottom: -1px;
display: block;
`

const Input = styled.input`
background: rgb(226, 226, 227);
border-radius: 10px;
border: none;
line-height: 20px;
height: 50px;
padding-top: 14px;
color: black;
font-size: 20px;
transition: .3s;
margin-bottom:20px;
`

  const DatesView = styled.div`
  display: inline-block;
  width: calc(33.33% - 5px);
  height: 45px;
  background: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  z-index:0;
  transition:all .3s ease-in-out .01s, z-index 0s;
  box-shadow: 10px 10px 30px rgba(219, 154, 99, 0);
  &:hover{
    
    box-shadow: 10px 10px 30px rgba(219, 154, 99, 0.35);
    background:#db9a63;
    z-index:1;
  }
  `

  const DatesTitle = styled.p`
  z-index:1
  `

const Title = styled.h3`
text-align:center;
background:white;
border-radius:20px;
box-shadow:10px 10px 30px rgba(0,0,0,0.1);
padding:20px;
z-index:1;
position:relative;
`

const Btn = styled.div`
width:200px;
`

const Scroll = styled.div`
height:calc(100vh - 275px);
border-radius:10px;
background:rgb(250,250,250);
padding-left:20px;
padding-right:20px;
overflow:scroll;
position:relative;
margin-top:-30px;
padding-top:40px;
z-index:0;
`
const ListItem={
  Body:styled.div`
  height:50px;
  margin-bottom:20px;
  cursor:pointer;
  &:hover img{
    transform:scale(1.2);
  }
  `,
  Img:styled.img`
    border-radius:100px;
    width:50px;
    height:50px;
    display:block;
    position:absolute;
    transition:.3s ease-in-out;
  `,
  Fio:styled.div`
  margin-left: 60px;
  font-weight: bold;
  font-size: 12px;
  `,
  Specialization:styled.div`
  margin-left: 60px;
  font-size: 12px;
  margin-top: -4px;
  color: rgb(100,100,100);
  font-weight: 300;
  `,
  Rating:styled.div`
  margin-left: 60px;
  font-size: 11px;
  margin-top: -4px;
  color: #bd7d48;
  `,
  View:(props)=>{
    const {name,specialization,rating,avatar} = props.data
    
    return(
      <ListItem.Body onClick={()=>props.onClick()}>
        <ListItem.Img src={avatar}/>
        <ListItem.Fio>{name}</ListItem.Fio>
        <ListItem.Specialization>{specialization}</ListItem.Specialization>
        <ListItem.Rating>{rating}</ListItem.Rating>
      </ListItem.Body>
      )
  }
 
}
