import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectDateTime,selectServices,selectVariants } from '../../actions/PageActions';
import PageView from '../PageView';
import CheckBox from '../components/CheckBox';
import { BtnBack } from '../components/BackBtn';
import ym from 'react-yandex-metrika'
import { withRouter } from 'react-router-dom';

class SuccessPage extends Component {
  constructor(props) {
    super(props)
    this.state={
    }
    this.input={}
  }
  componentDidMount(){
    const {user_input,user_data} = this.props
    if(user_input.selected_variants==null || user_input.selected_variants.length==0){
      if(this.props.match.params.URL_KEY==null)
        this.props.history.push('../');
      else
        this.props.history.push('../'+this.props.match.params.URL_KEY);

       this.props.openPage("SelectData")
    }
  }

  render() {
    const {user_input,user_data} = this.props
    const {} = this.state

    return(
      <PageView>
        <div className="row">
          <div className="col-md-3"/>
          <div className="col-md-6">
           <h1 className='text-center'>Запись успешно создана!</h1>
           <hr/>
          </div>
          <div className="col-md-3"/>

        </div>

        <div className="row">
          <div className="col-md-3"/>
          <div className="col-md-6">
            {user_input.selected_variants.map(variant=>(
              <div>
                <b>{variant.service.title}</b>
                <p>{variant.company.title} {variant.timeVariant.time}</p>
              </div>
           ))}
          </div>
          <div className="col-md-3"/>

        </div>

        <div className="row">
          <div className="col-md-3"/>
          <div className="col-md-6">
          <hr/>
           <small className='text-center d-block mb-3'>На указанную почту {user_data.email} придет подтверждение записи,<br/> а так же на Ваш телефон {user_data.phone}</small>
           <p  className='text-center'><b>Ждем Вас {user_input.selected_date} на сеанс</b></p>
          </div>
          <div className="col-md-3"/>

        </div>
        <BottomContent isVisible={true}>
            <Btn className='btn'
              onClick={()=>{
                ym('reachGoal', 'recreateOrder')
                this.props.selectVariants([])
                this.props.selectServices([])
                this.props.selectDateTime({})
                if(this.props.match.params.URL_KEY==null)
                  this.props.history.push('../');
                else
                  this.props.history.push('../'+this.props.match.params.URL_KEY);
                this.props.openPage("SelectData")
              }}>ЗАПИСАТЬСЯ ЕЩЁ</Btn>
              
          </BottomContent>
      </PageView>
    )
  }
}





const Btn = styled.div`
border: 1px solid #FF4159;
border-radius:180px;
font-size:13px;
color:#FF4040;
float: right;
margin-top: 10px;
margin-right: 10px;
&:hover{
  color:white;
  background:#FF4040;
}
`
const BottomContent = styled.div`
background:white;
width:100vw;
height:50px;
transition:.3s;
left:0;
box-shadow:0px 0px 30px rgba(0,0,0,0.08);
position:fixed;
bottom:${props=>props.isVisible?0:-50}px
`


const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectDateTime: (arg) => dispatch(selectDateTime(arg)), 

    selectVariants: (arg) => dispatch(selectVariants(arg)), 
    selectServices: (arg) => dispatch(selectServices(arg)), 
    
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
    user_data: store.user.user_data,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SuccessPage))
