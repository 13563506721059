import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {setUserInfo } from '../../actions/UserAct';
import { openPage,selectDateTime } from '../../actions/PageActions';
import PageView from '../PageView';
import ym from 'react-yandex-metrika'
import Input from '../components/Input';
import CheckBox from '../components/CheckBox';
import { BtnBack } from '../components/BackBtn';
import { BottomContent, Btn } from './SelectData';
import { withRouter,useNavigate } from 'react-router-dom';


class OrderType extends Component {
  constructor(props) {
    super(props)
    this.state={
      wantGetAlert:true
    }
    this.input={}
  }

  componentDidMount() {
    global.ev('WIDGET_NEW_OPEN_SELF_INFO')
    ym('hit', '/UserInfo')
    const {user_data} = this.props

    if(user_data.fullname!=null)
      global.ev('WIDGET_NEW_INPUT_NAME',{data:user_data.fullname})

    if(user_data.phone!=null)
      global.ev('WIDGET_NEW_INPUT_PHONE',{data:user_data.phone})

    if(user_data.email!=null)
      global.ev('WIDGET_NEW_INPUT_EMAIL',{data:user_data.email})

    this.input.Fio.value=user_data.fullname
    this.input.Phone.value=user_data.phone==null?'+7 (':user_data.phone
    this.input.Email.value=user_data.email
    this.input.AlertSMS.value=user_data.notify_by_sms

    global.maskPhone(this.input.Phone)

    this.input.Phone.focus()
  }

  CreateOrder(){
    
    const {user_input,user_data} = this.props

    

    const data = {
      "phone": user_data.phone.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('+',''),
      "fullname": user_data.fullname,
      "email": user_data.email,
      "comment": "Запись создана в быстром виджете! Комментарий клиента: "+user_data.comment,
      "notify_by_sms": user_data.notify_by_sms,
      "api_id": "quicksearch",
      "appointments":[]
    }

    global.cookie.set('phone',user_data.phone.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('+',''))

    global.cookie.set('email',user_data.email)
    global.cookie.set('fullname',user_data.fullname)
    
    
    let SortedVariants_By_companyId = []// тут услуги, разбитые по филиалам
    
    user_input.selected_variants.forEach((variant,i)=>{
      let index = SortedVariants_By_companyId.findIndex(v=>v.company.id==variant.company.id)
      
      if(index==-1){
        
        SortedVariants_By_companyId.push({
          company:variant.company,
          booking:[{
            service:variant.service,
            timeVariant:variant.timeVariant
          }]
        })
      }else{
        
        SortedVariants_By_companyId[index].booking.push({
          service:variant.service,
          timeVariant:variant.timeVariant
        })
      }
      
    /*
      data.appointments.push({
        "id": i+1,
        "services": [
          variant.service.id
        ],
        "staff_id": 0,
        "datetime": variant.timeVariant.datetime
      })
      */
    })

    let Promises = []

    SortedVariants_By_companyId.forEach(companyBook=>{
      let localData = data
      companyBook.booking.forEach((book,i)=>{
        localData.appointments.push({
          "id": i+1,
          "services": [
            book.service.id
          ],
          "staff_id": 0,
          "datetime": book.timeVariant.datetime,
          "custom_fields":{
            Type:'quicksearch',
            ClientID_widget_new:global.ClientID_widget_new,//айди юзера в метрике виджета
            ClientID_main:global.ClientID_main //айди юзера в на основном сайте
          }
        })
      })

    
      
      //отправка на сервер по каждой компании и записи в нее

      Promises.push(
        new Promise((resolve,reject)=>{
          
          global.API_task({
            operation:"makeBook",
            company_id:companyBook.company.id,
            data:JSON.stringify(localData)
          }).then(res=>{ 
            
            if(res!=null && res[0]!=null && res[0].record_id!=null){
              
              let coast = 0
              user_input.selected_services.forEach(serv=>{
                coast+=serv.price_max
              })
              ym('reachGoal', 'bookService')

              ym('reachGoal', 'order',{record_id:res.record_id})

              global.ev('WIDGET_NEW_CREATE_ORDER',{
                record_id:res.record_id,
                company_id:companyBook.company.id,
                
              })
              resolve()
            }else{
              reject()
            }
            
          }).catch(()=>{
            reject()
          })  
        })
      )

    })
 
   
    Promise.allSettled(Promises).then((prom)=>{
      let oneIsOk=false
      prom.forEach(pr=>{
        
        if(pr.status=='fulfilled')
          oneIsOk=true
      })
      
      if(oneIsOk){
        ym('hit', '/SuccessPage')

       
        if(this.props.match.params.URL_KEY==null)
          this.props.history.push('/done');
        else
          this.props.history.push('/key/'+this.props.match.params.URL_KEY+'/done')

        //this.props.openPage("SuccessPage")
      }else{
        ym('reachGoal', 'bookService_error')
        alert('Не удалось создать запись. Проверьте корректность выбранной информации')
      }
    })
    
   
  }

  render() {
    const {user_input} = this.props
    const {wantGetAlert} = this.state

    return(
      <PageView>
        <div className="row">
          <div className="col-lg-4 col-md-3"/>
          <div className="col-lg-4 col-md-6">
            

            
            <Input
              Title="Ваше Имя"
              Placeholder=""
              Name="Fio"
              Ref={this.input}
              onChange={(text)=>{
                global.ev('WIDGET_NEW_INPUT_NAME',{data:text})
              }}
            />
            <Input
              Title="Номер телефона"
              Placeholder="+7"
              Name="Phone"
              Type="phonenumber"
              Ref={this.input}
              onChange={(text)=>{
                global.ev('WIDGET_NEW_INPUT_PHONE',{data:text})
              }}
            />
            <Input
              Title="E-mail"
              Type="email"
              Placeholder="example@email.com"
              Name="Email"
              Ref={this.input}
              onChange={(text)=>{
                global.ev('WIDGET_NEW_INPUT_EMAIL',{data:text})
              }}
            />
            <Input
              Title="Комментарий"
              Placeholder=""
              Name="Comment"
              Ref={ this.input }
              onChange={ (text) => {
                global.ev('WIDGET_NEW_INPUT_COMMENT', { data:text })
              } }
            />

            <CheckBox
            label="Хочу получить напоминание о записи по SMS"
            onChange={(value)=>this.setState({wantGetAlert:value})}
            isChecked={true}/>
            
            {wantGetAlert?
            <Input
              LabelStyle={{marginTop:10}}
              Title="За сколько часов уведомить Вас?"
              Placeholder="3"
              Name="AlertSMS"
              Type="number"
              Min="1"
              onChange={(e)=>{
                if(e.target.value=="" || e.target.value*1<=0)
                  e.target.value=1
              }}
              Ref={this.input}
            />
            :null}

            
          </div>
          <div className="col-lg-4 col-md-3"/>
          <BottomContent isVisible={true}>
          <Btn className='btn'
              onClick={()=>{
                
                if(!global.checkReqvireds(this.input,["AlertSMS"])) return
                var userData = {
                  fullname:this.input.Fio.value,
                  phone:this.input.Phone.value,
                  email:this.input.Email.value,
                  comment:this.input.Comment.value
                }

                try{
                  if(this.input.AlertSMS.value!="" && wantGetAlert)
                    userData.notify_by_sms=this.input.AlertSMS.value
                  else{
                    if(this.input.AlertSMS.value=="" && wantGetAlert)
                      userData.notify_by_sms=3
                    else userData.notify_by_sms=null
                  }
                }catch(e){
                  userData.notify_by_sms=null
                }
                

                this.props.setUserInfo(userData)
                this.CreateOrder()
              }}>ЗАПИСАТЬСЯ</Btn>

            <BtnLeft className='btn'    
              onClick={()=>{
                this.props.selectDateTime({
                  dateTime:null,
                  company:null
                })
              this.props.openPage("SelectDateTime")
              }}>НАЗАД</BtnLeft>
          </BottomContent>
        </div>
      </PageView>
    )
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectDateTime: (arg) => dispatch(selectDateTime(arg)), 
    setUserInfo: (arg) => dispatch(setUserInfo(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
    user_data: store.user.user_data,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(OrderType))

const Title = styled.h3`
font-size:16px;
`
const Descr = styled.p`
font-size:11px;
`


const BtnLeft = styled.div`
border: none;
border-radius: 180px;
font-size: 13px;
background: none;
color: white;
float: left;
min-width:100px;
margin-top: 10px;
position:absolute;
top:0px;
left:0px;
margin-left: 12px; 
&:hover{
  background: white;
  color:#FF4159;
}
`

const DateTime = styled.div`
height: 80px;
border-radius: 10px;
text-align: center;
line-height: 19px;
font-size: 18px;
font-weight: 600;
margin-bottom: 10px;
padding-top: 20px;
background: rgb(247,247,247);

`

const ListItem={
  Body:styled.div`
  height:50px;
  margin-bottom:20px;
  cursor:pointer;
  &:hover img{
    transform:scale(1.2);
  }
  `,
  Img:styled.img`
    border-radius:100px;
    width:50px;
    height:50px;
    display:block;
    position:absolute;
    transition:.3s ease-in-out;
  `,
  Fio:styled.div`
  margin-left: 60px;
  font-weight: bold;
  font-size: 12px;
  `,
  Specialization:styled.div`
  margin-left: 60px;
  font-size: 12px;
  margin-top: -4px;
  color: rgb(100,100,100);
  font-weight: 300;
  `,
  Rating:styled.div`
  margin-left: 60px;
  font-size: 11px;
  margin-top: -4px;
  color: #bd7d48;
  `,
  View:(props)=>{
    const {name,specialization,rating,avatar} = props.data
    
    return(
      <ListItem.Body onClick={()=>props.onClick()}>
        <ListItem.Img src={avatar}/>
        <ListItem.Fio>{name}</ListItem.Fio>
        <ListItem.Specialization>{specialization}</ListItem.Specialization>
        <ListItem.Rating>{rating}</ListItem.Rating>
      </ListItem.Body>
      )
  }
 
}