import React from 'react';
import styled from 'styled-components';



export default class Input extends React.Component {
    constructor(props) {
        super()
        this.state = {
        }
        this.key = global.random()
        this.startValue=null
    }

    componentDidMount() {
        this.ready(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        this.ready(nextProps)
    }

    ready(props){
        const {Name,Ref,Value} = props
        if(this.startValue!=Value)
            Ref[Name].value=Value
    }
    
    render (){
        const {Title,Placeholder,onClick,onChange,onKeyUp,Name,Ref,Disabled,Style,LabelStyle,Type,Min} = this.props
        return ([
            <Label style={LabelStyle} for={this.key} key={this.key+"L"}>{Title}</Label>,
            <InputView style={Style} id={this.key} key={this.key+"I"}
            placeholder={Placeholder==null?Title:Placeholder}

            ref={ref=>Ref[Name]=ref}
            disabled={Disabled}
            onClick={(e)=>{try{onClick(e)}catch{}}}
            onChange={(e)=>{try{onChange(e)}catch{}}}
            onKeyUp={(e)=>{try{onKeyUp(e)}catch{}}}
            type={Type}
            min={Min}
            className="form-control"/>
        ])
    }
}

const Label = styled.label`
font-size:14px;
opacity:0.7;
display:block;
`

const InputView = styled.input`
font-size:20px;
border: 1px #f2f5f5 solid;
width:100%;
margin-bottom:10px;
`