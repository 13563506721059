import React, { Component } from 'react';
import styled from 'styled-components';


export default class PageView extends Component {
  constructor(props) {
    super()
    this.state={

    }
  }

  render() {
    return(
      <Page className="container p-3">
       {this.props.children}
      </Page>
    )
  }
}


const Page = styled.div`
width:100vw;

height:calc(100vh - 30px);
transition:1s ease-in-out;
`
