import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage } from '../../actions/PageActions';
import PageView from '../PageView';


export default class CollapseListElement extends Component {
    constructor(props) {
        super()
        this.state={
            isOpen:props.isOpen===true?true:false
        }
    }

    componentDidMount() {
        
    }

    render() {
        const {isOpen} = this.state
        const {childHeight} = this.props
        return(
            <List isOpen={isOpen}>
                <Title onClick={()=>this.setState({isOpen:!isOpen})}>{this.props.Title}</Title>
                <Collapsed isOpen={isOpen} childHeight={childHeight} Collapsed={this.props.Collapsed}>
                    {this.props.Collapsed.map(e=>(
                        <div style={{height:childHeight}}>
                            {e}
                        </div>
                    ))}
                </Collapsed>
            </List>
        )
    }
}



const Title = styled.div`
height: 55px;
width: 100%;
cursor: pointer;
border: 1px solid rgb(238, 238, 238);
padding: 15px;
font-size: 14px;
transition:1s;
`
const Collapsed = styled.div`
overflow:hidden;
width:100%;
height:${props=>props.isOpen? props.childHeight*props.Collapsed.length-1 : 0}px;
transition:1s ease-in-out;
padding-left:30px;
background:rgb(242, 242, 242);

`

const List = styled.div`
width:100%;
height:max-content;
${props=>props.isOpen? `
${Title}{
    background:white;
}`:null}
`