import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster,selectDate,selectCompanies,selectTime,selectServices,selectVariants} from '../../../actions/PageActions';
import { openPopUp } from '../../../actions/PopUpActions';
import PageView from '../../PageView' 
import ym from 'react-yandex-metrika'
import Loading from '../../components/Loading';
import { up, down, between, only } from 'styled-breakpoints';
import { BottomContent, Btn } from '../SelectData';

class SelectData extends Component {
  constructor(props) {
    super(props)
    this.state={
      Variants:[],
      nowLoading:true,
      SelectedVariants:props.user_input.selected_variants,
      selectedCompanyID:null
    }
    props.user_input.selected_companies.forEach(comp=>{
      this.state.Variants.push({
        company:comp,
        services:[]
      })
    })

    this.getDateTimePerCompanies()

   
  }

  getDateTimePerCompanies(){
    const {user_input} = this.props
    
    let arr =[]
    user_input.selected_services.forEach(serv=>{
      

      arr.push(
        new Promise(r=>{
          global.API_task({
            operation:"getDateTimePerCompanies",
            service_id:serv.id,
            date:user_input.selected_date,
            master_id: user_input.master?.id,
            Companies:JSON.stringify(user_input.selected_companies.map(comp=>comp.id))
          }).then(res=>{ 
            
            let Variants = this.state.Variants.slice(0)
            res.forEach(comp=>{
              if(comp.data.length>0){//Если есть доступные окна для записи по этой услуге то
               
                
                let CompanyVariantIndex = Variants.findIndex(v=>v.company.id == comp.id)
                  if(CompanyVariantIndex!=-1){
                  Variants[ CompanyVariantIndex ].services.push({
                    data:serv,
                    timeVariants:comp.data.filter(timeVariant => {
                      let hour =  timeVariant.time.substr(0,2)*1
                      let minut =  timeVariant.time.substr(3,2)
                      if(minut!='00')
                        minut=1 
                      else 
                        minut=0

                      return ( 
                        hour >= user_input.selected_time.start && 
                        hour + minut <= user_input.selected_time.end
                      )
                    })
                  })
                }
                debugger
                
                
              }
            })
          
            this.setState({Variants},()=>r())
          }).catch(er=>{
            debugger
          })
        })
      )

      


    })
    
    Promise.allSettled(arr).then(r=>{
      this.setState({nowLoading:false})
    })
    
  }

  checkSelectedTimeInterval(company,service,timeVariant){
    const {Variants,SelectedVariants} = this.state
    
    let selectedVariant = SelectedVariants.find(v=>v.service.id==service.data.id)
    if(selectedVariant==null) return false 
    return(
      selectedVariant.company.id == company.id && 
      selectedVariant.timeVariant.time == timeVariant.time
    )
  }

  checkEanabledServiceBlock(company,service){
    const {Variants,SelectedVariants} = this.state
    
    let selectedVariant = SelectedVariants.find(v=>v.service.id==service.data.id)
    if(selectedVariant==null) return true
    return(
      selectedVariant.company.id == company.id
    )
  }
 

  render() {
    const {Variants,SelectedVariants,nowLoading,selectedCompanyID} = this.state
    const {user_input} = this.props
    
    return(
      <PageView>
        <Cont  className="row justify-content-center mb-4">
          <div className="col-lg-8 ">

          <Table>
            <tr>
                <Left>
                  
                </Left>
                <Right>
                  <Time className='d-inline pt-1'>{user_input.selected_date} c {user_input.selected_time.start}:00 до {user_input.selected_time.end}:00</Time>
                  <BtnCh className='d-inline btn btn-sm btn-light ms-3'
                  onClick={()=>{
                    global.ev('WIDGET_NEW_CANSEL_SERVICES')
                    ym('reachGoal','back_to_main_page')
                    this.props.selectVariants([])
                    this.props.openPage('SelectData')
                  }}>ИЗМЕНИТЬ</BtnCh>
                </Right>
            </tr>

            <tr>
              <Left>
                <b>ВЫБЕРИТЕ ПОДХОДЯЩУЮ ЗАПИСЬ</b>
              </Left>
              <Right/>
            </tr>
          </Table>

          {Variants.map(variant=>{
            return(
              <Element className='row'>
                <div className='col-md-3'>
                  <AdressTitle>{variant.company.title}</AdressTitle>
                </div>
                <div className='col-md-8' style={{position:'relative',minHeight:'150px'}}>
                  {variant.services.map((service, i)=>(
                    <ServiceBlock isEnabled={selectedCompanyID==variant.company.id || selectedCompanyID==null}>
                      <ServiceTitle>{service.data.title}</ServiceTitle>
                      {service.timeVariants.map(timeVariant=>(
                          <TimeInterval 
                          isSelected={this.checkSelectedTimeInterval(variant.company,service,timeVariant)}
                          onClick={()=>{

                            let SelectedVariants_new =null
                            if(selectedCompanyID!=variant.company.id){//произошла смена компании
                              SelectedVariants_new=[]
                            }else{
                              SelectedVariants_new = SelectedVariants.slice(0)
                            }
                            this.setState({selectedCompanyID:variant.company.id})


                            //поиск уже выбранных записей где время накладывается на новую запись
                            let time_new = timeVariant.time.substr(0,2)*100 +timeVariant.time.substr(3,2)*1
                            let IndexOfOld = SelectedVariants_new.findIndex(sv => {
                              
                              let time_old = sv.timeVariant.time.substr(0,2)*100 +sv.timeVariant.time.substr(3,2)*1
                              let seanceMinutes = sv.timeVariant.sum_length/60
                              if(time_new==time_old) return true
                              /*
                                else{
                                  if(time_new>time_old){//смотрим, не накладывается ли старая запись на новую при длительности ее сеанса
                                    let hours = Math.floor(seanceMinutes/60) 
                                    let minutes = sv.timeVariant.sum_length/60%60
      
                                    time_old+=hours*100 + minutes
                                    if(time_new==time_old) return true
                                  }else{// смотрим, не накладываелся ли новая запись по длительности на старую

                                  }
                                
                              }
                              */
                              
                              
                            }) 
                          
                            if(IndexOfOld>=0){
                              console.group('deleted')
                                console.error(SelectedVariants_new)
                                SelectedVariants_new.splice(IndexOfOld,1)
                                console.error(SelectedVariants_new)
                              console.groupEnd()
                            
                            }
                            

                            
                            
                            
                            
                            
                            let index = SelectedVariants_new.findIndex(v=>v.service.id==service.data.id)
                            if(index==-1){
                              SelectedVariants_new.push({
                                service:service.data,
                                company:variant.company,
                                timeVariant:timeVariant
                              })
                            }else{
                              let selectedServiceVariant = SelectedVariants_new[index]
                              if(selectedServiceVariant.company.id == variant.company.id){//если это та же услуга, в рамках одной компании 
                                if(selectedServiceVariant.timeVariant.time==timeVariant.time){
                                  SelectedVariants_new.splice(index,1)//если то же самое время - то убрать выбор
                                }else{//если врему другое - то заменить время
                                  SelectedVariants_new[index].timeVariant = timeVariant
                                }
                                
                              }else{//Если это другая компания и уже выбранная услуга - то заменить данные выбора
                                SelectedVariants_new[index]={
                                  service:service.data,
                                  company:variant.company,
                                  timeVariant:timeVariant
                                }
                              }
                            }
                            
                            global.ev('WIDGET_NEW_SELECT_SERVICE_TIME	',{data:SelectedVariants_new})
                            this.setState({SelectedVariants:SelectedVariants_new})
                          }}>{timeVariant.time}</TimeInterval>
                        )
                      )}
                      <br></br>
                      {i!=variant.services.length-1?<hr></hr>:null}
                      
                    </ServiceBlock>

                  )
                  )} 
                 

                  {variant.services.length==0?
                    nowLoading?<Loading/>:
                  "Запись на выбранные услуги в указанное время не доступны в этом филиале":null}
                </div>
              </Element>
            )

          })}

          <BottomContent isVisible={SelectedVariants.length>0}>
            <Btn className='btn'
              onClick={()=>{
               
                ym('reachGoal','select_time')
                this.props.selectVariants(SelectedVariants)
                this.props.openPage("UserInfo")
              }}>СОЗДАТЬ ЗАПИСЬ</Btn>
              <BtnLeft className='btn'    
              onClick={()=>{
                global.ev('WIDGET_NEW_CANSEL_SERVICES')
                ym('reachGoal','back_to_main_page')
                this.props.selectVariants([])
                    this.props.openPage('SelectData')
              }}>НАЗАД</BtnLeft>
          </BottomContent>

          
          </div>
        </Cont>
      </PageView>
    )
  }
}


const BtnLeft = styled.div`
border: none;
border-radius: 180px;
font-size: 13px;
background: none;
color: white;
float: left;
min-width:100px;
margin-top: 10px;
position:absolute;
top:0px;
margin-left: 12px; 
&:hover{
  background: white;
  color:#FF4159;
}
`

const Cont = styled.div`
padding-bottom:20vh
`




const Element = styled.div`
background:#FAFAFA;
margin-top:10px;
margin-bottom:10px;
padding:20px;
border-radius:10px;
`

const BtnCh = styled.p`
${down('md')} {
  &{
    margin-left:0px !important;
    float: right;
margin-top: -38px;
  }
}
`
const Time = styled.p`
color: #FF4159;
${down('md')} {
  &{
    margin-top: -15px;
  }
}
`

const ServiceBlock = styled.div`
transition:.3s;
${props=> props.isEnabled?`
opacity:1;
`:`
opacity:0.5;
`}
&:hover{
  opacity:1;
}
`


const AdressTitle = styled.p`
text-transform: uppercase;
font-size:18px;
color:rgb(80,80,80);
font-weight:bold;
${down('md')} {
  font-size:24px;
}
`
const ServiceTitle = styled.p`
font-size:13px;
`
const TimeInterval = styled.div`
display:inline-block;

padding:10px 20px;
border-radius:20px;margin-right:10px;
font-size:14px;
cursor:pointer;
box-shadow:5px 5px 20px rgba(0,0,0,0);
transition:.3s;
width:75px;
text-align:center;
margin-bottom:10px;
${props=>props.isSelected?`
background:#db9a63;
color: #6b3c15;
`:`
background:white;
`}
&:hover{
  box-shadow:5px 5px 20px rgba(0,0,0,0.05)
}


${down('md')} {
  font-size:18px;
  width: calc(50vw - 46px);
}
`



const Table = styled.table`
width:100%;
font-size:13px;
& td{
  vertical-align:top;
}
& .Right{
  float:right;
  width:100%;
  text-align:right;
}
${down('md')} {
  & td{
    vertical-align:top;
    display: block;
  width: 100%;
  }
  & td .d-inline,
  & td p{
    
    display: block !important;
    text-align:left;
    width:max-content;
  }
}
`

const Left = (props)=>{
  return(
    <td  width='40%'>
      <div className='Left'>{props.children}</div>
    </td>
  )
}

const Right = (props)=>{
  return(
    <td  width='60%'>
      <div className='Right'>{props.children}</div>
    </td>
  )
}



const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectOrderType: (arg) => dispatch(selectOrderType(arg)), 
    selectMaster: (arg) => dispatch(selectMaster(arg)), 
    selectService: (arg) => dispatch(selectService(arg)), 
    selectDate: (arg) => dispatch(selectDate(arg)), 
    selectCompanies: (arg) => dispatch(selectCompanies(arg)), 
    selectVariants: (arg) => dispatch(selectVariants(arg)), 
    selectTime: (arg) => dispatch(selectTime(arg)), 

    selectServices: (arg) => dispatch(selectServices(arg)), 


    openPopUp: (arg) => dispatch(openPopUp(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectData)