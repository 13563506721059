import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster,selectDate,selectCompanies,selectTime,selectServices} from '../../../actions/PageActions';
import { openPopUp } from '../../../actions/PopUpActions';
import PageView from '../../PageView';
import CollapseListElement from '../../components/CollapseListElement';
import Loading from '../../components/Loading';
import { BtnBack } from '../../components/BackBtn';
import ServiceView from './ServiseView'
import { up, down, between, only } from 'styled-breakpoints';

import ym from 'react-yandex-metrika'


import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import SelectCompanies from './SelectCompanies';
import SelectService from './SelectService';



class SelectData extends Component {
  constructor(props) {
    super(props)
    this.state={
      selected_timePeriod:{
        start:10,
        end:22
      },
      nouislider:{
        start:10,
        end:22
      }
    }
   
    this.selectedDate=null
    
  }

  componentDidMount(){

    const {user_input} = this.props
    this.selectedDate.valueAsDate=user_input.selected_date==null? new Date():new Date(user_input.selected_date)
    this.props.selectDate(this.selectedDate.value)

    this.setState({nouislider:user_input.selected_time})
  }

  


  render() {
    const {selected_Companies,selected_timePeriod,nouislider} = this.state
    const {user_input} = this.props
    return(
      <PageView>
        <div className="row justify-content-center mb-4">
          <MainView className="col-lg-8 ">

            <Table>

              <tr>
                <Left>
                  <h4 className='mb-4'>Быстрая запись<br></br>на услугу</h4>
                </Left>
                <Right>
                  
                </Right>
              </tr>

              <tr>
                <Left>
                  <p>Выберите нужную дату:</p>
                </Left>
                <Right>
                  <DateView 
                  ref={ref=>this.selectedDate=ref}
                  onChange={(e)=>{
                    global.ev('WIDGET_NEW_SELECT_DATE',{data:e.target.value})
                   
                    
                    ym('reachGoal','set_date_order',{date:e.target.value})
                    
                    this.props.selectDate(e.target.value)
                    e.target.blur()
                  }} className='form-control mb-3' type='date'/>
                </Right>
              </tr>

              <tr >
                <Left>
                  <p>Выберите временной диапазон поиска:</p>
                </Left>
                <Right>
                  <Range className='mb-4'>
                    <Time>{user_input.selected_time.start}:00 - {user_input.selected_time.end}:00</Time>
                    <Nouislider
                    
                    range={{ min: 10, max: 22 }}
                    start={[nouislider.start, nouislider.end]}
                    step={1}
                    connect
                    
                    onSlide={(e)=>{
                      global.ev('WIDGET_NEW_SELECT_TIME',{
                        start:e[0]*1,
                        end:e[1]*1
                      })
                      
                      this.props.selectTime({
                        start:e[0]*1,
                        end:e[1]*1
                      })
                    }}/>
                  </Range>
                </Right>
              </tr>

             
{1<0?
              <tr>
                <Left>
                  <p>Выберите студию:</p>
                </Left>
                <Right>
                  {user_input.selected_companies.map((comp,i)=>(
                    <Element.View
                    title={comp.title}
                    onDelete={()=>{
                      
                      let Comp = user_input.selected_companies.slice(0)
                      Comp.splice(i,1)
                      
                      this.props.selectCompanies(Comp)
                    }}/>
                  ))
                  }

                  {user_input.selected_companies.length<3?
                    <AddElement.View onClick={()=>{
                      
                      this.props.openPopUp(
                        <SelectCompanies/>
                      )
                    }
                  } title='Добавить другую студию'/>
                  :null}
                  
                </Right>
              </tr>

             
              :null}
              
            </Table>

            <SelectService POPstyle={{width:'100%',padding:0}}/>

            
          </MainView>
        </div>
        <BottomContent isVisible={user_input.selected_services.length>0 &&
                  user_input.selected_companies.length>0}>
            <Btn className='btn'
              onClick={()=>{

                global.ev('WIDGET_NEW_SERVICES_CONFIM',{data:user_input.selected_services.map(s=>{
                  return{
                    id:s.id,
                    title:s.title
                  }
                })})

                ym('hit', '/selectService')
                ym('reachGoal', 'selectService',user_input.selected_services.map(serv => ({
                  id:serv.id,
                  title:serv.title,
                  price_max:serv.price_max
                  })))
                this.props.openPage("SelectDateTime")
              }}>НАЙТИ ПОДХОДЯЩУЮ ЗАПИСЬ</Btn>
          </BottomContent>
      </PageView>
    )
  }
}

export const BottomContent = styled.div`
background:#FF4040;
width:100vw;
height:50px;
transition:.3s;
left:0;
box-shadow:0px 0px 30px rgba(0,0,0,0.08);
position:fixed;
bottom:${props=>props.isVisible?0:-50}px
`

export const Btn = styled.div`
border: 1px solid #FF4159;
border-radius:180px;
font-size:13px;
display:block;
margin-top: 10px;
margin-right: auto;
margin-left: auto;
width:240px;

  color:#FF4040;
  background:white;
&:hover{
  color:white;
  background:rgba(255,255,255,0.3);
}


${down('md')} {
  position:absolute;
  right: 20px;
  margin-left: 0;    
  margin-right: 0;    
}
`

const DateView = styled.input`
height: 35px;
border-radius:100px;
background:#FAFAFA;
font-weight:bold;
font-size:13px;
`

const Time = styled.div`
margin-bottom:5px;
`

const Table = styled.table`
width:100%;
margin-bottom:20px;
font-size:13px;
& td{
  vertical-align:top;
}
& .Right{
  float:right;
  width:100%;
}

${down('md')} {
  & td{
    vertical-align:top;
    display: block;
  width: 100%;
  }
}

${up('md')}{
  position: fixed;
top: 30px;
width: 900px;
left: calc(50vw - 450px);
background: white;
z-index: 100;
border-bottom: 1px solid rgb(240,240,240);

}

`

const MainView = styled.div`
${up('md')}{
  padding-top:200px;
}
`

const Range = styled.div`
margin-top:5px;
& .noUi-target.noUi-ltr.noUi-horizontal.noUi-txt-dir-ltr{
  height: 10px;
  background: #EAEAEA;
  border: none;
}

& .noUi-connect{
  background:#FF772C;
}

& .noUi-handle.noUi-handle-lower, & .noUi-handle.noUi-handle-upper{
  width: 24px;
  height: 24px;
  right: -7px;
  top: -7px;
  border-radius: 100px;
  border: 4px solid white;
  background: #FF772C;
  outline:none !important;
  box-shadow:5px 5px 10px rgba(0,0,0,0.05);
}

& .noUi-handle:before,& .noUi-handle:after{
  display:none;
}
`

const AddElement = {
  Body:styled.div`
  font-weight: 300;
  line-height: 13px;
  border:1px transperent solid;
  padding: 10px 20px;
  cursor:pointer;
  width: 100%;
  position:relative;
  margin-bottom:5px;
  color:#FF4059;
  & img{
    margin-right:5px;
  }
  `,
  View:(props)=>{
    return(
      <AddElement.Body onClick={()=>props.onClick()}>
        <img src='./img/Add.svg'/>
        {props.title}
      </AddElement.Body>
    )
  }
}

const Element = {
  Body:styled.div`
  background: #FAFAFA;
  border: 1px solid #D8D8D8;
  border-radius: 500px;
  font-weight: 600;
  line-height: 12px;
  font-size: 12px;
  padding: 10px 20px;
  width: 100%;
  position:relative;
  margin-bottom:10px;
  &:hover div{
    opacity:1
  }
  `,
  Coast:styled.p`
  margin: 0;
  margin-top: 8px;
  font-weight: 300;
  color: #FF4040;
  font-size: 14px;
  `,
  Delete:styled.div`
  height:15px;
  opacity:0;
  transition:.2s;
  width:15px;
  text-align:center;
  line-height: 12px;
  position: absolute;
  right: 10px;
  font-weight:300;
  cursor:pointer;
  top: calc(50% - 7px);
  `,
  View:(props)=>{
    return(
      <Element.Body>
        {props.title}
        {props.coast!=null?
        <Element.Coast>{props.coast}р</Element.Coast>
      :null}
        <Element.Delete onClick={()=>props.onDelete()}>x</Element.Delete>
      </Element.Body>
    )
  }
}

const Left = (props)=>{
  return(
    <td  width='40%'>
      <div className='Left'>{props.children}</div>
    </td>
  )
}

const Right = (props)=>{
  return(
    <td  width='60%'>
      <div className='Right'>{props.children}</div>
    </td>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectOrderType: (arg) => dispatch(selectOrderType(arg)), 
    selectMaster: (arg) => dispatch(selectMaster(arg)), 
    selectService: (arg) => dispatch(selectService(arg)), 
    selectDate: (arg) => dispatch(selectDate(arg)), 
    selectCompanies: (arg) => dispatch(selectCompanies(arg)), 
    selectTime: (arg) => dispatch(selectTime(arg)), 

    selectServices: (arg) => dispatch(selectServices(arg)), 


    openPopUp: (arg) => dispatch(openPopUp(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectData)