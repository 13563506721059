import React, { Component } from 'react';
import styled from 'styled-components';


export default class Loading extends Component {
    constructor(props) {
        super()
        this.state={
            step:false
        }
    }

    componentDidMount() {
        this.nextStep()
    }

    nextStep(){
        try{
            this.setState({step:!this.state.step})
            setTimeout(()=>this.nextStep(),300)
        }catch{}
    }

    render() {
        const {step} = this.state
        return(
            <Box step={step}/>
        )
    }
}


const Box = styled.div`
width:50px;
height:50px;
transition:.3s;
background:#db9a63;
border-radius:100px;
position:absolute;
left:calc(50% - 25px);
top:calc(50% - 25px);
transform:scale(${props=>props.step?0.5:1.5});
`