import React from 'react';
import ReactDOM from 'react-dom';
import App from './View/App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/configureStore'; // исправлено
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import './App.css';
import './global'
import { YMInitializer } from 'react-yandex-metrika'


// Add the Firebase services that you want to use




const urlParams = global.urlParams()
let UserID =  null

if (urlParams.ClientID == null){
  UserID = null
}else{
  UserID = urlParams.ClientID
  global.UserID = urlParams.ClientID
}

let ClientID_widget_new = global.cookie.get('_ym_uid')


ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <YMInitializer accounts={[84733873]}
        version="2"
        options={{
          clickmap:true,
          trackLinks:true,
          webvisor:true,
          accurateTrackBounce:true,
          userParams: {
            ClientID_main:UserID,
            ClientID_widget_new
          }
        }}/>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
