import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster,selectCompanies} from '../../../actions/PageActions';
import PageView from '../../PageView';
import CollapseListElement from '../../components/CollapseListElement';
import Loading from '../../components/Loading';
import { BtnBack } from '../../components/BackBtn';
import {Companies} from '../../../reducers/page'


class SelectCompanies extends Component {
  constructor(props) {
    super(props)
    this.state={
      selected_Companies:props.user_input.selected_companies
    }
  
    
  }


  render() {
    const {user_input} = this.props
    const {selected_Companies} = this.state
    
    return(
      <POP>
        <p>Выберите студии для поиска</p>
        {Companies.map(comp=>(
          <Element.View
          isSelected={selected_Companies.findIndex(c=>c.id==comp.id)!=-1}
          title={comp.title}
          onSelect={()=>{
            let Comp = selected_Companies.slice(0)
            let index = Comp.findIndex(c=>c.id == comp.id)
            if(index==-1)
              Comp.push(comp)
            else
              Comp.splice(index,1)

            this.setState({selected_Companies:Comp})

            this.props.selectCompanies(Comp)
          }}/>
        ))}
      </POP>
    )
  }
}


const POP = styled.div`
  padding:20px;
  width:400px;
  max-width:calc(100wv - 40px);
  
`

const Element = {
  Body:styled.div`
  opacity: ${props=>props.isSelected?`1`:`0.4`};
  transition:.3s;
  cursor:pointer;
  font-weight: 600;
  line-height: 9px;
  padding: 10px 20px;
  width: 100%;
  position:relative;
  margin-bottom:10px;
  `,
  Coast:styled.p`
  margin: 0;
  margin-top: 8px;
  font-weight: 300;
  color: #FF4040;
  font-size: 14px;
  `,
  View:(props)=>{
    return(
      <Element.Body isSelected={props.isSelected} onClick={()=>props.onSelect()}>
        {props.title}
        {props.coast!=null?
        <Element.Coast>{props.coast}р</Element.Coast>
      :null}
      </Element.Body>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    selectCompanies: (arg) => dispatch(selectCompanies(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectCompanies)