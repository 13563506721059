import OrderType from "../View/pages/OrderType";
import SelectData from "../View/pages/SelectData";
import SelectDate from "../View/pages/SelectDate";
import SelectDateTime from "../View/pages/SelectDateTime";
import SelectMaster from "../View/pages/SelectMaster";
import SelectService from "../View/pages/SelectService";
import SMScode from "../View/pages/SMScode";
import SuccessPage from "../View/pages/SuccessPage";
import UserInfo from "../View/pages/UserInfo";

export  const Pages={
  SelectData:{
    title:"Стандартная запись yClients",
    view:<SelectData/>
  },
  SelectDateTime:{
    title:"Выбор времени",
    view:<SelectDateTime/>
  },
  UserInfo:{
    title:"Данные клиента",
    view:<UserInfo/>
  },






  
  SuccessPage:{
    title:"Запись создана",
    view:<SuccessPage/>
  }
}

export const Companies = [
  {
    id:177644,
    title:"Пражская"
  },
  {
    id:344272,
    title:"Домодедовская"
  },
  {
    id:389103,
    title:"Кузьминки"
  },
  {
    id:651855,
    title:"Коломенская"
  },
  {
    id:789145,
    title:"Юго-Западная"
  },
  {
    id:629097,
    title:"Митино"
  },
  {
    id:983410,
    title:"Беляево"
  }
]

const initialState = {
  user_input:{
    selected_services:[],
    selected_date:null,
    selected_dateTime:null,
    selected_variants:[],
    selected_time:{
      start:10,
      end:22
    },
    selected_companies:Companies,
  },
  page_data:{
    current_page:Pages.SelectData,
  }
 }








  
export function pageReducer(state = initialState, action) {
    switch (action.type) {
      case "openPage":
        return {
          ...state,
          page_data:{
            ...state.page_data,
            current_page:action.payload
          }
        }
      case "selectDate":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            selected_date:action.payload
          }
        }
      case "selectCompanies":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            selected_companies:action.payload
          }
        }
        case "selectTime":
          return {
            ...state,
            user_input:{
              ...state.user_input,
              selected_time:action.payload
            }
          }

        case "selectServices":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            selected_services:action.payload
          }
        }

      case "selectVariants":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            selected_variants:action.payload
          }
        }


























      case "selectOrderType":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            order_type:action.payload
          }
        }

      case "selectMaster":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            master:action.payload
          }
        }

      case "selectService":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            service:action.payload
          }
        }

      case "selectDateTime":
        return {
          ...state,
          user_input:{
            ...state.user_input,
            selected_dateTime:action.payload.dateTime,
            selected_company:action.payload.company
          }
        }

      default:
        return state;
    }
}