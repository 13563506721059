import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../../actions/UserAct';
import { openPage,selectCompanies,selectMaster,selectOrderType,selectService, selectServices} from '../../../actions/PageActions';
import { closePopUp } from '../../../actions/PopUpActions';
import PageView from '../../PageView';
import CollapseListElement from '../../components/CollapseListElement';
import Loading from '../../components/Loading';
import { BtnBack } from '../../components/BackBtn';
import { withRouter } from 'react-router-dom';
import qs from 'qs'
import { Companies } from '../../../reducers/page';

/*
categoris, filials, services, master
*/



class SelectService extends Component {
  constructor(props) {
    super(props)
    this.state={
      selected_services:props.user_input.selected_services,
      ServicesPerCategories:[],
      OpenedCategories:[7773718],
      filteredServices:null,
      canShow:true, 
      isError:false,
      YCservices:[]
    }
  
    this.UrlParam = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    Object.keys(this.UrlParam).forEach(p=>{
      if(this.UrlParam[p][0]!=null && (this.UrlParam[p][0]=='{' || this.UrlParam[p][0]=='['))
        this.UrlParam[p] = JSON.parse(this.UrlParam[p])
        
    })

    if(this.UrlParam.categoris!=null){
      this.state.OpenedCategories = this.UrlParam.categoris
    }else {
      if(props.user_input.selected_services!=null || this.UrlParam.filials!=null || this.UrlParam.master!=null)
        this.state.OpenedCategories = null
    }


    if(this.UrlParam.filials!=null){
      let selected = []
      this.UrlParam.filials.forEach(com=>{
        let comp = Companies.find(c=>c.id==com)
        if(comp?.id!=null)
          selected.push({...comp})
      })
      if(selected.length!=0)
        props.selectCompanies(selected)
      
    }

    if(this.UrlParam.master!=null){
      props.selectMaster({
        id:this.UrlParam.master*1
      })
    }
    
  }

  componentDidMount(){
    
    this.getServices()
  }
 

  componentWillReceiveProps(nextProps){
    if(nextProps.user_input.selected_services.length != this.state.selected_services.length){
      this.setState({selected_services:nextProps.user_input.selected_services})
    }
 
  }

  getServices(){
    const {user_input} = this.props

    
    
    global.API_task({
      operation:"getServices",
      master_id:this.UrlParam?.master || user_input.master?.id ,
    }).then(res=>{
      
      let categories = []
      res.forEach(comp=>{
        
        if(comp.data?.category!=null){
          
          comp.data.category.forEach(category=>{
            let c_index = categories.findIndex(c=>c.id==category.id)
            if(c_index != -1){
              if(categories[c_index].companies==null)
                categories[c_index].companies=[comp]
              else
                categories[c_index].companies.push(comp)
            }else{
              categories.push({
                ...category,
                companies:[comp]
              })
            }
          })

        }
      })

      

      


      let services = []
      res.forEach(comp=>{
        if(comp.data?.services!=null){

          comp.data.services.forEach(service=>{
            let c_index = services.findIndex(c=>c.id==service.id)
            if(c_index != -1){
              if(services[c_index].companies==null)
                services[c_index].companies=[comp]
              else
                services[c_index].companies.push(comp)
            }else{
              services.push({
                ...service,
                companies:[comp]
              })
            }
          })

        }
      })
      
      let YCservices = [...services]

      categories.forEach(category=>{
        category.services=[]
        let nexStepServices = []

        services.forEach(service=>{
          if(service.category_id == category.id)
            category.services.push(service)
          else
            nexStepServices.push(service)
        })
        services=nexStepServices
      })

      

      
      this.setState({ServicesPerCategories:categories,YCservices})
    }).catch(er=>{
        
        alert('Ошибка получения списка услуг. Попробуйте позже')
    })
  }

  openCategory(id){
    
    let OpenedCategories = this.state.OpenedCategories.slice(0)
    let index = OpenedCategories.findIndex(c => c ==id)
    if(index==-1)
      OpenedCategories.push(id)
    else
      OpenedCategories.splice(index,1)
      
    this.setState({OpenedCategories})
  }


  render() {
    const {user_input,POPstyle} = this.props
    const {ServicesPerCategories,Filter,selected_services,OpenedCategories,
      filteredServices, isError, canShow
    } = this.state
    
    if(!canShow) return <div/>

    if(isError) return (
      <div className='p-4'>
        <h3 className='text-center'>404 Виджет не найден</h3>
        <a className='text-center w-100 d-block' href='../'>Перейти в стандартный виджет</a>
      </div>
    )
   
    return(
      <POP style={POPstyle}>
        {selected_services.length>0?[

          selected_services.length==this.props.user_input.selected_services.length?null:
          <Btn
            className='btn btn-sm btn-light mb-2 float-start'
            onClick={()=>{
              this.props.selectServices(selected_services)
              this.props.closePopUp()
            }}>
              <img src='./img/Add.svg'/> Выбрать {selected_services.length} {global.okonchanie(selected_services.length,
                'услугу',
                'услуги',
                'услуг')}
          </Btn>,

         


          ]:null}
          {0<1?
        <input className='form-control search-service' placeholder='Поиск' onKeyUp={(e)=>{
          if(e.target.value.length==0)
          this.setState({Filter:null})
          else
          this.setState({Filter:e.target.value.toUpperCase()})
        
        }}/>:null}
        <Scroll>
          {ServicesPerCategories.length==0?<Loading/>:<>
            {OpenedCategories!=null && ServicesPerCategories.filter(categ=>OpenedCategories.findIndex(c=>c==categ.id)!=-1).length==0?<p>Категория услуг не найдена</p>:null}

            {this.state.YCservices.map(service=>{
              if(OpenedCategories!=null && OpenedCategories.findIndex(c=>c==service.category_id)==-1) return null
              if(Filter != null && service.title.toUpperCase().indexOf(Filter)==-1) return null
              if(this.UrlParam?.services!=null && this.UrlParam.services.findIndex(s=>s==service.id)==-1) return null
              if(this.UrlParam?.filials!=null && this.UrlParam.filials.filter(s=>service.companies.findIndex(com=>com.id==s)!=-1).length==0) return null


                    return(
                      <Element.View
                        isSelected={selected_services.findIndex(s=>s.id==service.id)!=-1}
                        title={service.title}
                        description={service.comment}
                        coast={service.price_max}
                        onSelect={(e)=>{
                          e.stopPropagation()
                          let Serv = selected_services.slice(0)
                          let index = Serv.findIndex(s=>s.id == service.id)
                          if(index==-1)
                            Serv.push(service)
                          else
                            Serv.splice(index,1)

                            
                          global.ev('WIDGET_NEW_SELECT_SERVICES',{data:Serv.map(s=>{
                            return{
                              id:s.id,
                              title:s.title
                            }
                          })})
                          //this.setState({selected_services:Serv})
                          this.props.selectServices(Serv)
                        }}/>
                      )
                    })} 


            
          </>}
        </Scroll>
        
      </POP>
    )
  }
}


const POP = styled.div`
  padding:20px;
  width:90vw;
  max-width:calc(100wv - 40px);
  height:max-contnet;
  position:relative;
  padding-top:0;
`

const Btn = styled.div`


& img{
  margin-right:8px;
}
&.clear{
  right:0px;
  width:150px;
}
`

const Scroll = styled.div`
  
  padding-bottom:300px;
`

const Categ = styled.div`
cursor:pointer;
position:relative;
  margin-bottom:40px;
`
const Arrow = styled.img`
position: absolute;
left: -9px;
top: -2px;
height: 30px;
width: 30px;
padding: 10px;
cursor: pointer;
transform:scaleY(-1);
transition: .3s ease-in-out;
${props=>props.isSelected}{
  transform:scaleY(1);
}
  `

const Title = styled.p`
  font-weight:bold;
  margin-left:20px;
  color:rgb(100,100,100);
`

const Element = {
  Body:styled.div`
  box-shadow: 5px 5px 30px rgba(0,0,0,0.04);
  border-radius:20px;
  ${props=>props.isSelected?`
  background: rgb(247, 232, 219);
  `:null}
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;    
  -ms-user-select: none;     
  user-select: none; 

  transition:.3s;
  cursor:pointer;
  padding: 20px 20px;
  width: 100%;
  position:relative;
  margin-top:30px;

  font-size: 18px;
  line-height: 22px;
  font-weight: 400;

  & small{
    line-height: 15px !important;
color: rgb(140,140,140);
display: block;
margin-top:10px;
    font-weight: 200;
    color:rgb(140,140,140);
  }
  `,
  Coast:styled.p`
  margin: 0;
  margin-top: 8px;
  font-weight: 600;
color: #FF4040;
font-size: 24px;
  `,
  View:(props)=>{
    return(
      <Element.Body isSelected={props.isSelected} onClick={(e)=>props.onSelect(e)}>
        
        {props.title}
        <br></br>
        <small>{props.description}</small>
        {props.coast!=null?
        <Element.Coast>{props.coast}р</Element.Coast>
      :null}
      </Element.Body>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    selectServices: (arg) => dispatch(selectServices(arg)), 
    selectCompanies: (arg)=>dispatch(selectCompanies(arg)),
    selectMaster: (arg)=>dispatch(selectMaster(arg)),

    closePopUp: (arg) => dispatch(closePopUp(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SelectService))