import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectOrderType } from '../../actions/PageActions';
import PageView from '../PageView';


class OrderType extends Component {
  constructor(props) {
    super(props)
    this.state={

    }
  }

  componentDidMount() {
    
  }

  render() {
    return(
      <PageView>
        <Vline/>
        <div className="row h-100">
          <Row className="col-md-6 align-self-center">
            <Icon src="./design/nail.svg"/>
            <Btn className="btn btn-light btn-lg d-block mx-auto"
              onClick={()=>{
                this.props.selectOrderType("Service")
                this.props.openPage("SelectService")
              }}>
              ВЫБРАТЬ УСЛУГУ
            </Btn>
          </Row>
          <Row className="col-md-6 align-self-center">
            <Icon src="./design/user.svg"/>
            <Btn className="btn btn-light btn-lg d-block mx-auto"
              onClick={()=>{
                this.props.selectOrderType("Master")
                this.props.openPage("SelectMaster")
              }}>
              ВЫБРАТЬ МАСТЕРА
            </Btn>
          </Row>
        </div>
      </PageView>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectOrderType: (arg) => dispatch(selectOrderType(arg)), 
  }
}


const mapStateToProps = (store) => {
  return {
    //page_data: store.page.page_data,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderType)



const Icon = styled.img`
height:200px;
width:200px;
object-fit:contain;
position:absolute;
left:calc(50% - 100px);
top:-180px;
transition:1s;
&:hover{
  top:-220px;
}
`

const Row = styled.div`
position:relative;

&:hover img{
  top:-220px;
}
`

const Btn = styled.div`
width:300px;
position:relative;
z-index:2;
transition:.7s ease-in-out;
box-shadow:10px 10px 30px rgba(219, 154, 99, 0.0);
&:hover{
  transform:scale(1.1);
  background:#db9a63;
  border-color:#db9a63;
  color:#6e431f;
  box-shadow:10px 10px 30px rgba(219, 154, 99, 0.26);
}
`

const Vline = styled.div`
height:400px;
width:1px;
background:rgb(235,235,235);
position:absolute;
top:calc(50vh - 200px);
left:calc(50vw - 0.5px);
`
